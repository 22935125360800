export const CICDTOOLS = [
  {
    name: 'Git',
    level: 'Ri',
  },
  {
    name: 'Gitlab/github',
    level: 'Ri',
  },
  {
    name: 'Jenkins',
    level: 'Ha',
  },
  {
    name: 'Docker',
    level: 'Ri',
  },
  {
    name: 'Kubernetes',
    level: 'Shu',
  },
  {
    name: 'OpenShift',
    level: 'Shu',
  },
  {
    name: 'Ansible',
    level: 'Ha',
  },
  {
    name: 'Puppet',
    level: 'Ha',
  },
  {
    name: 'Kakfa',
    level: 'Ha',
  },
  {
    name: 'Vagrant',
    level: 'Ha',
  },
  {
    name: 'Elasticserach',
    level: 'Ha',
  },
  {
    name: 'Relation DB',
    level: 'Ha',
  },
  {
    name: 'Cassandra',
    level: 'Ha',
  },
  {
    name: 'MongoDB',
    level: 'Ha',
  },
];
