import React from 'react';
import CV from '../../assets/cv-kong-2022.pdf';

const HeaderCallToAction = () => {
  return (
    <div className="header__cta">
      <a href={CV} className="btn" target="_blank" rel="noreferrer" style={{ display: 'none' }}>
        Download CV
      </a>
      <a href="#contact" className="btn btn-primary">
        Let&apos;s talk
      </a>
    </div>
  );
};

export default HeaderCallToAction;
