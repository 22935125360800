export const LANGUAGES = [
  {
    name: 'HTML',
    level: 'Ha',
  },
  {
    name: 'CSS',
    level: 'Shu',
  },
  {
    name: 'Java',
    level: 'Ri',
  },
  {
    name: 'Javascript',
    level: 'Ha',
  },
  {
    name: 'Typescript',
    level: 'Ha',
  },
  {
    name: 'Python',
    level: 'Shu',
  },
  {
    name: 'Kotlin',
    level: 'Ha',
  },
  {
    name: 'Go',
    level: 'Shu',
  },
];
