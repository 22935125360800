import React from 'react';
import './contact.css';
import { MdOutlineEmail } from 'react-icons/md';
import { SiLinkedin, SiTwitter } from 'react-icons/si';
import { useRef } from 'react';
import emailjs from '@emailjs/browser';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Contact = () => {
  const form = useRef();

  const sendEmail = e => {
    e.preventDefault();

    emailjs.sendForm('service_tt598oo', 'template_1n93pxr', form.current, 'ptB0K7PqpassrsdMq').then(
      result => {
        console.log(result.text);
        toast.success('🦄 The message has been sent successfully!', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      },
      error => {
        console.log(error.text);
        toast.error(
          '🦄 An error has occured while trying to send the message, please try again later on!',
          {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          },
        );
      },
    );

    e.target.reset();
  };

  return (
    <section id="contact">
      <h5>Get in touch</h5>
      <h2>contact me</h2>
      <div className="container contact__container">
        <div className="contact__cards">
          <article className="contact__card">
            <MdOutlineEmail className="contact__card-icon" />
            {/* <h4>Email</h4> */}
            <h5>newlight77@gmail.com</h5>
            <a href="mailto:newlight77@gmail.com">send a message</a>
          </article>
          <article className="contact__card">
            <SiLinkedin className="contact__card-icon" />
            {/* <h4>Linkedin</h4> */}
            <h5>kongto</h5>
            <a href="https://linkedin.com/in/kongto" target="_blank" rel="noreferrer">
              send a message
            </a>
          </article>
          <article className="contact__card">
            <SiTwitter className="contact__card-icon" />
            {/* <h4>Twitter</h4> */}
            <h5>newlight77</h5>
            <a href="https://twitter.com/newlight77" target="_blank" rel="noreferrer">
              send a message
            </a>
          </article>
          {/* <article className="contact__card">
            <RiMessage2Line className="contact__card-icon" />
            <h4>Whatsapp</h4>
            <h5>+33612345678</h5>
            <a
              href="https://api.whatsapp.com/send?phone=+33612345678"
              target="_blank"
              rel="noreferrer"
            >
              send a message
            </a>
          </article> */}
        </div>

        <form ref={form} onSubmit={sendEmail}>
          <input type="hidden" name="to_name" value="Kong" />
          <input type="text" name="from_name" placeholder="your full name" required />
          <input type="email" name="reply_to" placeholder="your email" required />
          <textarea name="message" cols="30" rows="10" placeholder="your message" required />
          <button type="submit" className="btn btn-primary">
            send message
          </button>
        </form>
        <ToastContainer />
      </div>
    </section>
  );
};

export default Contact;
