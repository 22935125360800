import React, { useEffect } from 'react';

export const THEMES = {
  dark: '',
  bluesky: 'bluesky',
  seagreen: 'seegreen',
  salmon: 'salmon',
  peach: 'peach',
  orchid: 'orchid',
  limon: 'limon',
  gold: 'gold',
};

const storedDarkMode = localStorage.getItem('darkMode');

const prefersDark = window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches;

const defaultDarkMode = storedDarkMode === 'true' || (storedDarkMode === null && prefersDark);

export type ThemeContextType = {
  darkMode: boolean;
  toogleDarkMode: () => void;
};

export const defaultThemeState = {
  darkMode: defaultDarkMode,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  toogleDarkMode: () => () => {},
};

export const ThemeContext = React.createContext<ThemeContextType>(defaultThemeState);

const changeDarkMode = (darkMode: boolean) => {
  switch (darkMode) {
    case true:
      document.body.classList.add('dark-content');
      localStorage.setItem('darkMode', 'true');
      break;
    default:
      document.body.classList.remove('dark-content');
      localStorage.setItem('darkMode', 'false');
      break;
  }
};

export const ThemeProvider = ({ children }: never) => {
  const [darkMode, setDarkMode] = React.useState(defaultThemeState.darkMode);

  const toogleDark = () => {
    setDarkMode(!darkMode);
  };

  useEffect(() => {
    changeDarkMode(darkMode);
  }, [darkMode]);

  return (
    <ThemeContext.Provider value={{ darkMode: darkMode, toogleDarkMode: toogleDark }}>
      {children}
    </ThemeContext.Provider>
  );
};
