export const FRAMEWORKS = [
  {
    name: 'React',
    level: 'Shu',
  },
  {
    name: 'Node JS',
    level: 'Ha',
  },
  {
    name: 'Nest JS',
    level: 'Ha',
  },
  {
    name: 'React',
    level: 'Shu',
  },
  {
    name: 'Angular',
    level: 'Ha',
  },
  {
    name: 'Flask',
    level: 'Shu',
  },
  {
    name: 'Spring',
    level: 'Ri',
  },
  {
    name: 'Cucumber',
    level: 'Ri',
  },
];
