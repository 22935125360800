import './theme-toggle.css';
import { ThemeContext } from './ThemeContext';
import React, { MouseEvent } from 'react';

function ThemeToggle() {
  const { darkMode, toogleDarkMode } = React.useContext(ThemeContext);

  const handleOnClick = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    toogleDarkMode();
  };

  return (
    <div className="dark-mode-toggle">
      <button onClick={handleOnClick}>{darkMode ? '🌙' : '🌞'}</button>
    </div>
  );
}

export default ThemeToggle;
