/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import './nav.css';
import { AiOutlineHome, AiOutlineUser, AiOutlineAppstore } from 'react-icons/ai';
import { BsBook } from 'react-icons/bs';
import { RiServiceLine, RiMessage2Line } from 'react-icons/ri';
import { AiOutlineBgColors } from 'react-icons/ai';
import { useState } from 'react';

const Nav = () => {
  const [activeNav, setActiveNav] = useState('#');

  const showMenu = () => {
    const menu = document.querySelector('.nav__menu');
  };

  return (
    <nav>
      <a
        href="#header"
        className={activeNav === '#' ? 'active' : ''}
        onClick={() => setActiveNav('#')}
      >
        <AiOutlineHome />
      </a>
      <a
        href="#about"
        className={activeNav === '#about' ? 'active' : ''}
        onClick={() => setActiveNav('#about')}
      >
        <AiOutlineUser />
      </a>
      <a
        href="#experience"
        className={activeNav === '#experience' ? 'active' : ''}
        onClick={() => setActiveNav('#experience')}
      >
        <BsBook />
      </a>
      {/* <a
        href="#services"
        className={activeNav === '#services' ? 'active' : ''}
        onClick={() => setActiveNav('#services')}
      >
        <RiServiceLine />
      </a> */}
      {/* <a
        href="#portfolio"
        className={activeNav === '#portfolio' ? 'active' : ''}
        onClick={() => setActiveNav('#portfolio')}
      >
        <AiOutlineAppstore />
      </a> */}
      <a
        href="#contact"
        className={activeNav === '#contact' ? 'active' : ''}
        onClick={() => setActiveNav('#contact')}
      >
        <RiMessage2Line />
      </a>
      {/* <button id="theme-menu" onClick={() => showMenu()}>
        <AiOutlineBgColors />
      </button> */}
    </nav>
  );
};

export default Nav;
