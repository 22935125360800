import React from 'react';
import './skills.css';
import { BsPatchCheckFill } from 'react-icons/bs';
import { LANGUAGES } from './languages';
import { FRAMEWORKS } from './frameworks';
import { CICDTOOLS } from './cicd-tools';
import { ARCHITECTURES_PATTERNS } from './architectures-patterns';

const Experience = () => {
  return (
    <section id="experience">
      <h5>What skills I have</h5>
      <h2>my skill set</h2>
      <div className="container experience__container">
        <div className="experience__legend">
          <span className="text-light">Reference : </span>
          <a
            className="text-white"
            href="https://www.brainbok.com/guide/pmp/study-notes/shu-ha-ri-model/"
            target="_blank"
            rel="noreferrer"
          >
            Shu Ha Ri
          </a>
        </div>

        <div className="experience__cards">
          <div className="experience__card">
            <h3>Languages</h3>
            <div className="experience__card-content">
              {LANGUAGES.map(({ name, level }) => {
                return (
                  <article key={name} className="experience__details">
                    <BsPatchCheckFill className="experience__details-icon" />
                    <div>
                      <h4>{name}</h4>
                      <small className="text-light">{level}</small>
                    </div>
                  </article>
                );
              })}
            </div>
          </div>
          <div className="experience__card">
            <h3>Frameworks</h3>
            <div className="experience__card-content">
              {FRAMEWORKS.map(({ name, level }) => {
                return (
                  <article key={name} className="experience__details">
                    <BsPatchCheckFill className="experience__details-icon" />
                    <div>
                      <h4>{name}</h4>
                      <small className="text-light">{level}</small>
                    </div>
                  </article>
                );
              })}
            </div>
          </div>
          <div className="experience__card">
            <h3>CI/CD/System Tools</h3>
            <div className="experience__card-content">
              {CICDTOOLS.map(({ name, level }) => {
                return (
                  <article key={name} className="experience__details">
                    <BsPatchCheckFill className="experience__details-icon" />
                    <div>
                      <h4>{name}</h4>
                      <small className="text-light">{level}</small>
                    </div>
                  </article>
                );
              })}
            </div>
          </div>
          <div className="experience__card">
            <h3>Architecture & Patterns</h3>
            <div className="experience__card-content">
              {ARCHITECTURES_PATTERNS.map(({ name, level }) => {
                return (
                  <article key={name} className="experience__details">
                    <BsPatchCheckFill className="experience__details-icon" />
                    <div>
                      <h4>{name}</h4>
                      <small className="text-light">{level}</small>
                    </div>
                  </article>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Experience;
