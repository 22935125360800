import React from 'react';
import './about.css';
import ME from '../../assets/kong-jump.png';
import { FaAward, FaUserTie } from 'react-icons/fa';

const About = () => {
  return (
    <section id="about">
      <h5>Get to know</h5>
      <h2>about me</h2>

      <div className="container about__container">
        <div className="about__me">
          <div className="about__me_image">
            <img src={ME} alt="about me" />
          </div>
        </div>

        <div className="about__content">
          <div className="about__cards">
            <article className="about__card">
              <FaUserTie className="about__card-icon" />
              <h3>Experience</h3>
              <div className="about__card-items">
                <div className="about__card-item">
                  <h5>18+</h5>
                  <small>years of exp.</small>
                </div>
                <div className="about__card-item">
                  <h5>10+</h5>
                  <small>projects</small>
                </div>
              </div>
            </article>

            <article className="about__card">
              <FaAward className="about__card-icon" />
              <h3>Craftsmanship</h3>
              <div className="about__card-items">
                <div className="about__card-item">
                  <h5>20+</h5>
                  <small>Workshops</small>
                </div>
                <div className="about__card-item">
                  <h5>100+</h5>
                  <small>Trainees</small>
                </div>
              </div>
            </article>
          </div>

          <div className="about__description">
            {/* prettier-ignore */}
            <p>
              Code, I have done that, a lot, but not enough.
              Experience, I don&apos;t have much and there are so many things I want to learn.
              I build software, write code, provide guidance to ones in need, coach teams and people to be more efficient and more productive.
            </p>
            <p>
              Many new languages, frameworks, libraries and tools have risen and fallen in the last
              few years. There are so many technologies I want to learn and master. All of them, but
              I can&apos;t, snif!
            </p>
            <p>
              My today&apos;s challenge is about delivery value, the lean way, with clean code and
              less technical debt. I want to share what I know and what I do, whatever that is good
              to share. The crafts way. Please do the same.
            </p>
            <span>Keywords :</span>
            <ul>
              <li>TDD / BDD / DDD / XP / SOLID / Clean Code / Clean / Hexagonal</li>
              <li>Java / Kotlin / Spring / Maven / Gradle / Hibernate</li>
              <li>Angular / React / Vue / NodeJs / NestJs / NextJs / Javascript / Typescript</li>
              <li>Python / Flask</li>
              <li>Reactive / OOP / Functional Programming</li>
              <li>Testing Strategy / Pyramid / Diamand / Test automation</li>
              <li>Microservices / Spark / Kafka / Elasticsearch / ELK / EDA / CQRS</li>
              <li>Docker / Kubernetes / Openshift / Terraform</li>
              <li>Azure / AWS / GCP - Infrastructure as Code</li>
              <li>Contunuous Integration / Deployment / Delivery - aka CI CD</li>
            </ul>
            <a href="#contact" className="btn btn-primary">
              Let&apos;s talk
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};

export default About;
