export const ARCHITECTURES_PATTERNS = [
  {
    name: 'Microservices',
    level: 'Ri',
  },
  {
    name: 'Event-Driven',
    level: 'Shu',
  },
  {
    name: 'CQRS',
    level: 'Ri',
  },
  {
    name: 'Clean/Hexagonal',
    level: 'Ri',
  },
  {
    name: 'N-Tier',
    level: 'Ri',
  },
  {
    name: 'Layered',
    level: 'Ri',
  },
  {
    name: 'Microkernel',
    level: 'Ri',
  },
];
