import React from 'react';
import { SiLinkedin, SiGithub, SiTwitter } from 'react-icons/si';

const HeaderSocials = () => {
  return (
    <div className="header__socials">
      <a href="https://linkedin.com/in/kongto" target="_blank" rel="noreferrer">
        <SiLinkedin />
      </a>
      <a href="https://twitter.com/newlight77" target="_blank" rel="noreferrer">
        <SiTwitter />
      </a>
      <a href="https://github.com/newlight77" target="_blank" rel="noreferrer">
        <SiGithub />
      </a>
      <a href="https://newlight77.medium.com" target="_blank" rel="noreferrer">
        <SiGithub />
      </a>
    </div>
  );
};

export default HeaderSocials;
