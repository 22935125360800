import React from 'react';
import './theme-selector.css';
import { Menu, MenuItem } from '@mui/material';
import { AiOutlineBgColors } from 'react-icons/ai';

const THEMES = [
  'deepskyblue',
  'darkindigoblue',
  'lightseagreen',
  'darkseagreen',
  'peachpuff',
  'salmon',
  'orchid',
  'blueviolet',
  'lemonchiffon',
  'gold',
];

const ThemeSelector = () => {
  const applyTheme = (theme: string) => {
    document.documentElement.setAttribute('data-theme', theme);
  };

  const storedTheme = localStorage.getItem('theme');
  applyTheme(storedTheme ? storedTheme : 'dark');

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleThemeSelected = (theme: string) => {
    localStorage.setItem('theme', theme);
    applyTheme(theme);
  };

  return (
    <div className="theme-selector__container">
      <label className="theme-selector" htmlFor="checkbox">
        <button id="theme-selector-button" onClick={handleClick}>
          <AiOutlineBgColors />
        </button>
      </label>
      <Menu id="basic-menu" anchorEl={anchorEl} open={open} onClose={handleClose}>
        {THEMES.map(theme => {
          return (
            <MenuItem key={theme} onClick={() => handleThemeSelected(theme)}>
              {theme}
            </MenuItem>
          );
        })}
      </Menu>
    </div>
  );
};

export default ThemeSelector;
