import React from 'react';
import './header.css';
import HeaderCallToAction from './CallToAction';
import HeaderSocials from './HeaderSocials';
import ME from '../../assets/me.jpg';
import MEV from '../../assets/kong-lake.png';

const Header2 = () => {
  return (
    <header id="header">
      <div className="container header__container">
        <div className="header__greeting">
          <h5>Hi, I&apos;m</h5>
          <h2 className="text-white">Kong</h2>
        </div>

        <HeaderSocials />

        <div className="header__card">
          <div className="header__card-imgBx">
            <img src={ME} alt="me" />
          </div>
          <div className="header__card-content">
            <div className="header__card-item">
              <h3>55</h3>
              <small>Posts</small>
            </div>
            <div className="header__card-item">
              <h3>4.5k</h3>
              <small>Followers</small>
            </div>
            <div className="header__card-item">
              <h3>11</h3>
              <small>Following</small>
            </div>
          </div>
          <h5>Fullstack Developer / Crafter</h5>
          <small>Tech Leader, Architect, Technical Coach, Writer</small>
        </div>

        <HeaderCallToAction />

        <div className="header__me">
          <img src={MEV} alt="me greeting" />
        </div>

        <a href="#contact" className="header__scroll_down">
          scroll down
        </a>
      </div>
    </header>
  );
};

export default Header2;
