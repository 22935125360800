import React from 'react';
import './testimonials.css';
import AVATAR1 from '../../assets/avatar1.jpg';
import AVATAR2 from '../../assets/avatar2.jpg';
import AVATAR3 from '../../assets/avatar3.jpg';
import AVATAR4 from '../../assets/avatar4.jpg';

// import Swiper core and required modules
import { Pagination } from 'swiper';

import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';

const data = [
  {
    avatar: AVATAR1,
    name: 'Francois',
    review:
      'Lorem ipsum dolor sit amet consectetur adipisicing elit. Quae tempora fugiat doloremque dolores corporis, quibusdam laudantium nesciunt necessitatibus voluptatum eum consequatur libero sapiente in magni tempore autem explicabo obcaecati. Cum.',
  },
  {
    avatar: AVATAR2,
    name: 'Laurent',
    review:
      'Lorem ipsum dolor sit amet consectetur adipisicing elit. Quae tempora fugiat doloremque dolores corporis, quibusdam laudantium nesciunt necessitatibus voluptatum eum consequatur libero sapiente in magni tempore autem explicabo obcaecati. Cum.',
  },
  {
    avatar: AVATAR3,
    name: 'Xavier',
    review:
      'Lorem ipsum dolor sit amet consectetur adipisicing elit. Quae tempora fugiat doloremque dolores corporis, quibusdam laudantium nesciunt necessitatibus voluptatum eum consequatur libero sapiente in magni tempore autem explicabo obcaecati. Cum.',
  },
  {
    avatar: AVATAR4,
    name: 'Dan',
    review:
      'Lorem ipsum dolor sit amet consectetur adipisicing elit. Quae tempora fugiat doloremque dolores corporis, quibusdam laudantium nesciunt necessitatibus voluptatum eum consequatur libero sapiente in magni tempore autem explicabo obcaecati. Cum.',
  },
];

const Testimonials = () => {
  return (
    <section id="testimonials">
      <h5>Review from clients</h5>
      <h2>testimonials</h2>

      <Swiper
        className="container testimonials__container"
        modules={[Pagination]}
        spaceBetween={40}
        slidesPerView={1}
        pagination={{ clickable: true }}
      >
        {data.map(({ avatar, name, review }) => {
          return (
            <SwiperSlide key={name} className="testimonial">
              <div className="testimonial__client-avatar">
                <img src={avatar} alt={name} />
              </div>
              <h5 className="testimonial__client-name">{name}</h5>
              <small className="testimonial__client-review">{review}</small>
            </SwiperSlide>
          );
        })}
      </Swiper>
    </section>
  );
};

export default Testimonials;
