import React from 'react';
import './footer.css';
import { SiLinkedin, SiGithub, SiTwitter } from 'react-icons/si';

const Footer = () => {
  return (
    <footer>
      {/* <a href="#header" className="footer_logo">
        Top
      </a> */}
      <ul className="permalink">
        <li>
          <a href="#header">Home</a>
        </li>
        <li>
          <a href="#about">About</a>
        </li>
        <li>
          <a href="#experience">Experience</a>
        </li>
        {/* <li>
          <a href="#services">Services</a>
        </li> */}
        {/* <li>
          <a href="#portfolio">Portfolio</a>
        </li> */}
        {/* <li>
          <a href="#testimonials">Testimonials</a>
        </li> */}
        <li>
          <a href="#contact">Contact</a>
        </li>
      </ul>

      <div className="footer__socials">
        <a href="https://linkedin.com" target="_blank" rel="noreferrer">
          <SiLinkedin />
        </a>
        <a href="https://github.com" target="_blank" rel="noreferrer">
          <SiGithub />
        </a>
        <a href="https://twitter.com" target="_blank" rel="noreferrer">
          <SiTwitter />
        </a>
      </div>

      <div className="footer__copyright">
        <small>&copy; Kong To. All rights reserved.</small>
      </div>
    </footer>
  );
};

export default Footer;
