/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import './app.css';
import About from './components/about/About';
import Contact from './components/contact/Contact';
import Experience from './components/skills/skills';
import Footer from './components/footer/Footer';
import Header from './components/header/Header';
import Nav from './components/nav/Nav';
import Portfolio from './components/portfolio/Portfolio';
import Services from './components/services/Services';
import Testimonials from './components/testimonials/Testimonials';
import TagManager from 'react-gtm-module';
import ThemeSelector from './components/theme-selector/ThemeSelector';
import ThemeToggle from './components/theme-dark-mode/ThemeToggle';
import { ThemeProvider } from './components/theme-dark-mode/ThemeContext';

TagManager.dataLayer({
  dataLayer: {
    event: 'pageview',
    pagePath: 'cv-react',
    pageTitle: 'Kong Portfolio',
  },
});

export const App = () => {
  return (
    <div className="app_container">
      <div className="right_header">
        <ThemeProvider>
          <ThemeToggle />
        </ThemeProvider>
        <ThemeSelector />
      </div>
      <Header />
      <Nav />
      <About />
      <Experience />
      {/* <Services /> */}
      {/* <Portfolio /> */}
      {/* <Testimonials /> */}
      <Contact />
      <Footer />
    </div>
  );
};
