import React from 'react';
import ReactDOM from 'react-dom';
import { App } from './App';
import './index.css';
import TagManager from 'react-gtm-module';

TagManager.initialize({
  gtmId: 'G-7PTVGSSE8D',
});

ReactDOM.render(<App />, document.querySelector('#root'));
